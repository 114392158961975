import { computed, Directive, input } from '@angular/core';
import { cn } from '@cf/core';
import { cva, type VariantProps } from 'class-variance-authority';

const buttonVariants = cva(
  'tw:inline-flex tw:items-center tw:justify-center tw:gap-2 tw:whitespace-nowrap tw:!rounded-lg tw:text-sm tw:font-medium tw:leading-snug tw:transition-colors tw:focus-visible:outline-none tw:focus-visible:ring-1 tw:focus-visible:ring-ring tw:disabled:pointer-events-none tw:disabled:opacity-50 [&_svg]:tw:pointer-events-none [&_svg]:tw:size-4 [&_svg]:tw:shrink-0',
  {
    variants: {
      variant: {
        default:
          'tw:bg-blue-50 tw:text-white tw:hover:tw:bg-blue-50/90 tw:border tw:border-blue-50 tw:disabled:bg-gray-20 tw:disabled:text-gray-40 tw:disabled:border-gray-20 tw:no-underline',
        secondary:
          'tw:border tw:text-raven-70 tw:border-raven-70 tw:bg-white tw:hover:border-blue-50 tw:active:border-blue-60 tw:disabled:border-gray-20 tw:disabled:text-gray-40',
        ghost: 'tw:hover:tw:bg-accent tw:hover:tw:text-accent-foreground tw:no-underline',
        link: 'tw:text-blue-50 tw:!no-underline tw:underline-offset-4 tw:hover:!underline tw:whitespace-normal',
        icon: 'tw:bg-transparent tw:text-blue-50',
        yellow: 'tw:bg-yellow-new tw:text-gray-80 tw:hover:bg-yellow-20 tw:active:bg-yellow-40',
        warning:
          'tw:border tw:text-red-50 tw:border-red-50 tw:bg-white tw:hover:border-red-40 tw:hover:text-red-40 tw:active:border-red-60 tw:active:text-red-60 tw:disabled:border-gray-20 tw:disabled:text-gray-40 tw:no-underline',
      },
      size: {
        default: 'tw:h-10 tw:px-4 tw:py-2',
        sm: 'tw:h-8 tw:px-3 tw:text-xs',
        lg: 'tw:h-10 tw:px-8',
        icon: 'tw:size-10',
        link: 'tw:p-0',
      },
      loading: {
        true: 'tw:pointer-events-none',
        false: '',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

type ButtonVariant = VariantProps<typeof buttonVariants>;

@Directive({
  selector: 'a[cfButton], button[cfButton]',
  host: {
    '[class]': 'classes()',
    '[disabled]': 'disabled()',
  },
})
export class ButtonDirective {
  variant = input<ButtonVariant['variant']>('default');
  size = input<ButtonVariant['size']>('default');
  class = input<string>('');
  loading = input<boolean>(false);
  disabled = input<boolean>(false);

  classes = computed(() => {
    return cn(
      buttonVariants({
        variant: this.variant(),
        size: this.variant() === 'link' && this.size() === 'default' ? 'link' : this.size(),
        loading: this.loading(),
      }),
      this.class(),
    );
  });
}
